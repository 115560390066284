import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import LogoWhite from "../../images/teenyfy_white_logo.png";
import Fb from "../../images/facebook-icon.png";
import Instagram from "../../images/instagram-icon.png";
import LinkedIn from "../../images/linkedin-icon.png";
import axios from "axios";
import iso9001 from "../../images/iso-9001-small.svg";
import iso27001 from "../../images/iso-27001-small.svg";
import Twitter from "../../images/twitter-icon.png";
import G2 from "../../images/G2.png";
import { Link } from "gatsby";

const Footer = () => {
  function handleData(e) {
    e.preventDefault();

    let data = ` Subscribe-Email: ${emailValue}`;

    let requestData = { text: "```" + data + "```", name: "Leads" };

    const url =
      "https://hooks.slack.com/services/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";

    let options = {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      data: JSON.stringify(requestData),
      url,
    };
    axios(options)
      .then((response) => {
        setEmailValue("");
        setStatus(true);
      })
      .catch((error) => {
        console.log("K_____ error :- ", error);
      });
  }
  const [emailValue, setEmailValue] = useState("");
  const [status, setStatus] = useState(false);
  return (
    <footer className="bg-[#3D948E] font-open-sans">
      <div className="container px-5 py-[40px] mx-auto">
        <div className="md:flex md:flex-wrap md:text-left text-center order-first">
          <div className="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2 className="xs:font-poppins text-white tracking-widest text-sm mb-3">
              <a href="/">
                <img
                  src={LogoWhite}
                  alt={LogoWhite}
                  className="mx-auto md:mx-[0] w-[140px] h-[41px]"
                />
              </a>
            </h2>
            <nav className="list-none mb-10">
              <ul>
                <li>
                  <a
                    href="mailto:info@teenyfy.com"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    info@teenyfy.com
                  </a>
                </li>
                <li>
                  <span className="font-open-sans text-white/50 text-[16px] hover:text-gray-800 font-[400]">
                    42, Medway Gardens,
                    <br /> ha02rj , London , UK
                  </span>
                </li>
                <li>
                  <a
                    href="https://www.producthunt.com/posts/teenyfy-url-shortner-analyser?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-teenyfy&#0045;url&#0045;shortner&#0045;analyser"
                    target="_blank"
                    className="flex justify-center"
                  >
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=352391&theme=light"
                      alt="Teenyfy&#0032;URL&#0032;Shortner&#0032;&#0038;&#0032;Analyser - Make&#0032;your&#0032;links&#0032;more&#0032;memorable | Product Hunt"
                      style="width: 250px; height: 54px;margin-top:1rem"
                      width="250"
                      height="54"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.g2.com/products/teenyfy/reviews"
                    target="_blank"
                    rel="nofollow"
                    className="flex justify-center"
                  >
                    <img
                      src={G2}
                      alt=""
                      style="margin-top:1rem"
                      // width="250"
                      // height="54"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="lg:w-1/5 md:w-1/2 w-full px-4 xl:pl-[60px]">
            <h2 className="xs:font-poppins text-white mb-6 text-[16px] leading-[28px] font-[700]">
              Company
            </h2>
            <nav className="list-none mb-10">
              <ul>
                <li>
                  <a
                    href="/about-us/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/pricing/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="/contact-us/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="lg:w-1/5 md:w-1/2 w-full px-4">
            <h2 className="xs:font-poppins text-white mb-6 text-[16px] leading-[28px] font-[700]">
              Useful Links
            </h2>
            <nav className="list-none mb-10">
              <ul>
                <li>
                  <a
                    href="/privacy-policy/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/terms-n-conditions/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="/cookie-policy/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Cookie Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/certifications/"
                    className="font-open-sans text-white/50 text-[16px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    Certifications
                  </a>
                </li>
              </ul>
            </nav>
            <div className="hidden md:block w-full">
              <div className="flex gap-4">
                <Link to="/certifications/">
                  <img src={iso9001} alt="ISO 9001" width="97" height="86" />
                </Link>
                <Link to="/certifications/">
                  <img src={iso27001} alt="ISO 27001" width="97" height="86" />
                </Link>
              </div>
              <p className="text-xs text-white text-center font-roboto pt-5">
                © {new Date().getFullYear()} Teenyfy by{" "}
                <a
                  href="https://www.011bq.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  011BQ
                </a>
              </p>
            </div>
          </div>
          <div className="lg:w-2/5 md:w-1/2 w-full px-4">
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              <a
                href="https://www.facebook.com/teenyfy"
                target="_blank"
                className="text-white"
              >
                <img src={Fb} alt="" loading="lazy" />
              </a>
              <a
                href="https://www.linkedin.com/showcase/teenyfy-official/"
                target="_blank"
                className="ml-3 text-white"
              >
                <img src={LinkedIn} alt="" loading="lazy" />
              </a>
              <a
                href="https://www.instagram.com/teenyfyofficial/"
                target="_blank"
                className="ml-3 text-white"
              >
                <img src={Instagram} alt="" loading="lazy" />
              </a>
              <a
                href="https://twitter.com/teenyfy/"
                target="_blank"
                className="ml-3 text-white"
              >
                <img src={Twitter} alt="" loading="lazy" />
              </a>
            </span>
            <p className="block md:hidden text-center font-open-sans text-white mb-5 mt-[20px] text-[12px] leading-[14px] font-[400]">
              © {new Date().getFullYear()} Teenyfy by{" "}
              <a href="https://www.011bq.com/" target="_blank" rel="noreferrer">
                011BQ
              </a>
            </p>
            <h2 className="hidden md:block text-left xs:font-poppins text-white mb-5 text-[24px] leading-[28px] font-[400] pt-[10px]">
              Subscribe Newsletter
            </h2>
            <div className="hidden md:block justify-center items-end md:justify-start">
              <form className="" onSubmit={handleData}>
                <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
                  <label
                    for="footer-field"
                    className="font-open-sans text-black text-[20px] leading-[44px] hover:text-gray-800 font-[400]"
                  >
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="rounded-xl border-none focus:outline-white outline-2 font-open-sans text-[16px] bg-green w-full  h-[60px] pl-[33px] transition-colors duration-200 ease-in-out placeholder-black"
                      value={emailValue}
                      name="email"
                      onChange={(e) => setEmailValue(e.target.value)}
                      required
                    />
                    {status && (
                      <div className="text-whtie xs:font-poppins font-bold text-sm italic pt-2 pl-4">
                        Thank you !
                      </div>
                    )}
                  </label>
                </div>
                <button className="block marker:text-white bg-white py-2 font-open-sans focus:outline-none border border-white hover:border hover:border-black mt-8 w-[160px] h-[54px] px-[30px] text-center rounded-[40px] font-[16px] font-[700]">
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="md:hidden w-full pt-8">
            <div className="flex gap-4 justify-center">
              <Link to="/certifications/">
                <img
                  src={iso9001}
                  alt="ISO 9001"
                  width="97"
                  height="86"
                  loading="lazy"
                />
              </Link>
              <Link to="/certifications/">
                <img
                  src={iso27001}
                  alt="ISO 27001"
                  width="97"
                  height="86"
                  loading="lazy"
                />
              </Link>
            </div>
            <p className="text-xs text-white text-center font-roboto pt-5">
              © {new Date().getFullYear()} Teenyfy by{" "}
              <a href="https://www.011bq.com/" target="_blank" rel="noreferrer">
                011BQ
              </a>
            </p>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="teenyfy_consent"
        id="consent-style"
        style={{ background: "#28ADA7" }}
        buttonStyle={{
          color: "#1F1F39",
          fontSize: "16px",
          background: "white",
          padding: "10px 50px",
          "border-radius": "25px",
          "font-weight": "600",
        }}
        className="font-open-sans"
        expires={150}
      >
        <span
          style={{ fontSize: "14px", color: "white" }}
          className="font-open-sans"
        >
          If you require any more information or have any questions about our{" "}
          <a href="/privacy-policy/">Privacy Policy</a> for Website and{" "}
          <a href="/terms-n-conditions/">Terms of Use</a>.For more information,
          please review our <a href="/cookie-policy/">Cookie Policy</a>.
        </span>
      </CookieConsent>
    </footer>
  );
};

export default Footer;
