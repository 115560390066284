import React from "react";

const GetStarted = ({ backgroundCol, btntext, btnlink, targetlink }) => {
  return (
    <a
      href={btnlink ? btnlink : "/"}
      target={targetlink ? targetlink : "_self"}
      className={`${
        backgroundCol ? backgroundCol : "bg-dark-green text-white md:ml-[0px]"
      } flex justify-center items-center  w-[120px] h-[40px] px-[20px] text-center mt-0 rounded-[40px] font-[16px] font-[700] no-underline leading-[21px] border-2 border-transparent`}
    >
      {btntext ? btntext : "Sign up"}
    </a>
  );
};

export default GetStarted;
