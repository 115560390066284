import * as React from "react";

const Teenify = (props) => (
  <svg
    width="150"
    height="60"
    viewBox="0 0 244 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0592 29.7653C25.0592 29.997 24.9462 30.2851 24.884 30.4546C23.5047 34.5398 19.7569 40.9301 12.3855 40.9301C6.80042 40.9301 2.93949 36.9581 2.93949 30.5112V19.1148C2.19331 19.1712 1.55455 19.1712 1.09666 19.1712C0.350481 19.1712 0 18.9395 0 18.2502V16.007C0 15.4308 0.231768 15.0862 0.86489 15.0862C1.44149 15.0862 2.13112 15.0862 2.93949 15.1426V8.00644C2.93949 7.25496 3.34085 6.85379 3.97397 6.85379H6.91346C7.54659 6.85379 7.89142 7.25496 7.89142 7.94994V15.2048C9.84732 15.1482 12.442 15.0918 13.94 15.0918C14.3979 15.0918 14.6861 15.3799 14.6861 15.8997V18.2615C14.6861 19.0073 14.3979 19.1825 13.8778 19.1825C12.3233 19.1825 9.84733 19.1261 7.82925 19.0695V28.2229C7.82925 33.2289 8.98243 36.6304 13.3012 36.6304C17.8518 36.6304 20.6726 31.5056 21.8258 28.6297C21.9388 28.3415 22.0575 28.2285 22.3459 28.2285C22.7471 28.2285 25.0536 29.3246 25.0536 29.7822L25.0592 29.7653Z"
      fill="#28ADA7"
    />
    <path
      d="M21.0287 27.5786C21.0287 20.4424 26.2123 14.3401 32.9506 14.3401C37.3825 14.3401 40.6668 17.3348 40.6668 21.4199C40.6668 28.0984 34.5617 31.4941 28.3434 31.4941C27.7104 31.4941 26.9641 31.4941 26.4441 31.4377C27.7104 34.8899 31.396 36.8505 35.7714 36.8505C42.1647 36.8505 45.8505 32.6468 47.5181 28.6182C47.6312 28.3301 47.7499 28.2171 47.9759 28.2171C48.4339 28.2171 50.6837 29.3131 50.6837 29.8274C50.6837 30.0025 50.6271 30.2286 50.5707 30.4036C48.7844 35.698 43.6571 40.9357 35.076 40.9357C26.495 40.9357 21.0229 35.8675 21.0229 27.5786H21.0287ZM36.4045 21.3634C36.4045 19.3462 34.9631 18.1371 32.894 18.1371C28.9766 18.1371 25.5793 22.2222 25.6357 26.9457C25.6357 27.1774 25.6357 27.5786 25.6922 27.8103C26.2689 27.8667 26.8454 27.9232 27.5917 27.9232C32.1423 27.9232 36.4045 25.618 36.4045 21.3634Z"
      fill="#28ADA7"
    />
    <path
      d="M46.7156 27.5786C46.7156 20.4424 51.8993 14.3401 58.6375 14.3401C63.0694 14.3401 66.3538 17.3348 66.3538 21.4199C66.3538 28.0984 60.2486 31.4941 54.0304 31.4941C53.3973 31.4941 52.6512 31.4941 52.1311 31.4377C53.3973 34.8899 57.0829 36.8505 61.4583 36.8505C67.8517 36.8505 71.5374 32.6468 73.2049 28.6182C73.318 28.3301 73.4367 28.2171 73.6629 28.2171C74.1208 28.2171 76.3706 29.3131 76.3706 29.8274C76.3706 30.0025 76.3141 30.2286 76.2575 30.4036C74.4713 35.698 69.344 40.9357 60.7629 40.9357C52.1818 40.9357 46.71 35.8675 46.71 27.5786H46.7156ZM62.0915 21.3634C62.0915 19.3462 60.65 18.1371 58.581 18.1371C54.6635 18.1371 51.2661 22.2222 51.3226 26.9457C51.3226 27.1774 51.3226 27.5786 51.3792 27.8103C51.9558 27.8667 52.5323 27.9232 53.2786 27.9232C57.8292 27.9232 62.0915 25.618 62.0915 21.3634Z"
      fill="#28ADA7"
    />
    <path
      d="M90.6611 33.4494C90.6611 29.8785 91.0624 26.8839 91.0624 23.4881C91.0624 20.4935 89.9658 18.6515 87.2015 18.6515C81.3281 18.6515 78.0439 29.9349 78.0439 36.7265V39.1448C78.0439 39.9528 77.6425 40.3539 76.9471 40.3539H74.1829C73.3746 40.3539 73.0862 39.8907 73.0862 39.1448V16.2897C73.0862 15.2558 73.4311 14.9677 74.1829 14.9677H76.9471C77.6369 14.9677 78.0439 15.3123 78.0439 16.1202C78.0439 17.2164 77.699 21.4145 77.586 23.3752L77.8177 23.4316C79.3723 18.9397 83.0014 14.3348 88.5299 14.3348C93.4818 14.3348 96.1895 17.1543 96.1895 21.8779C96.1895 23.6069 95.7882 27.867 95.7882 31.7205C95.7882 34.7716 96.998 36.6135 99.4739 36.6135C103.16 36.6135 105.98 31.8335 107.309 28.6129C107.422 28.3248 107.541 28.2117 107.772 28.2117C108.174 28.2117 110.48 29.3078 110.48 29.822C110.48 29.9971 110.424 30.2232 110.367 30.3983C109.101 34.1388 105.302 40.9304 98.5016 40.9304C94.3524 40.9304 90.6668 38.0543 90.6668 33.4494H90.6611Z"
      fill="#28ADA7"
    />
    <path
      d="M144.335 29.8218C144.335 29.9969 144.279 30.285 144.223 30.4545C142.436 35.5171 138.637 40.2406 131.842 40.2406C131.266 40.2406 130.746 40.1842 130.231 40.0655C129.943 49.3319 125.449 55.2646 116.93 55.2646C111.922 55.2646 108.349 52.2135 108.349 50.8913C108.349 50.1455 110.079 47.8968 110.825 47.8968C111.057 47.8968 111.226 48.0719 111.69 48.5295C112.786 49.5071 114.567 50.8913 117.218 50.8913C121.944 50.8913 125.799 47.6085 125.799 38.2857C125.799 37.133 125.913 32.8729 125.913 31.7824L125.681 31.7258C124.239 36.0991 120.553 40.9357 115.025 40.9357C109.898 40.9357 107.134 37.7094 107.134 31.5507V16.1257C107.134 15.3177 107.597 14.973 108.23 14.973H111.108C111.854 14.973 112.204 15.3742 112.204 16.1257C112.204 19.578 112.029 26.313 112.029 30.6297C112.029 34.1385 112.95 36.6189 116.116 36.6189C121.532 36.6189 125.33 26.4316 125.33 18.3689V16.1821C125.33 15.3742 125.675 14.973 126.308 14.973H129.304C129.994 14.973 130.339 15.3742 130.339 16.0692C130.339 16.9901 130.282 28.8499 130.226 35.5849C130.859 35.7601 131.548 35.8166 132.357 35.8166C137.082 35.8166 139.616 32.3643 141.114 28.6804C141.227 28.3358 141.345 28.2228 141.633 28.2228C142.034 28.2228 144.341 29.3753 144.341 29.8331L144.335 29.8218Z"
      fill="#28ADA7"
    />
    <path
      d="M170.253 29.8218C170.253 29.9969 170.197 30.285 170.14 30.4545C168.699 34.5961 165.477 38.1106 160.638 38.1106C159.66 38.1106 158.507 37.879 157.987 37.5908L157.874 37.7037C158.106 38.9129 158.338 40.7549 158.338 42.4838C158.338 48.8742 155.46 55.2645 149.067 55.2645C142.673 55.2645 140.887 48.5295 140.887 39.2632V19.2277C140.887 9.20982 144.516 0 152.81 0C157.473 0 160.643 3.33926 160.643 7.71251C160.643 14.4476 154.076 20.5497 145.613 23.0246V39.0881C145.613 45.3655 146.077 50.9478 149.411 50.9478C152.52 50.9478 154.018 46.3994 154.018 42.0262C154.018 39.2067 153.561 36.7885 153.04 35.2346C151.198 33.6243 149.756 30.7428 149.756 30.0535C149.756 28.9008 152.232 27.2905 153.443 27.2905C153.844 27.2905 154.076 27.4035 154.25 27.7481C155.284 29.5335 157.359 33.9633 160.988 33.9633C163.984 33.9633 165.941 31.3755 167.094 28.6691C167.207 28.3244 167.326 28.2115 167.558 28.2115C168.015 28.2115 170.266 29.364 170.266 29.8218H170.253ZM145.602 18.6513C150.61 17.0975 156.082 12.6056 156.082 7.83116C156.082 5.52588 154.759 4.14724 152.628 4.14724C148.309 4.14724 145.777 10.4811 145.602 18.6513Z"
      fill="#28ADA7"
    />
    <path
      d="M168.18 50.8913C168.18 50.1455 169.908 47.8968 170.654 47.8968C170.886 47.8968 171.118 48.0719 171.633 48.5861C172.667 49.5069 174.397 50.8913 177.049 50.8913C181.768 50.8913 185.629 47.6085 185.629 38.2857L185.686 31.7824L185.454 31.7258C184.07 36.0991 180.383 40.9357 174.855 40.9357C169.728 40.9357 166.964 37.7094 166.964 31.5507V16.1257C166.964 15.3177 167.427 14.973 168.061 14.973H170.938C171.685 14.973 172.034 15.3742 172.034 16.1257C172.034 19.578 171.859 26.313 171.859 30.6297C171.859 34.1385 172.78 36.6189 175.947 36.6189C181.361 36.6189 185.161 26.4316 185.161 18.3689V16.1821C185.161 15.3742 185.505 14.973 186.138 14.973H189.134C189.824 14.973 190.169 15.3742 190.169 16.0692V38.7491C190.169 48.8234 185.618 55.2702 176.749 55.2702C171.74 55.2702 168.167 52.2191 168.167 50.897L168.18 50.8913Z"
      fill="#28ADA7"
    />
    <path
      d="M203.372 51.8216C202.444 51.7943 201.571 51.1807 201.093 50.5807C200.602 49.9943 200.261 49.5852 200.152 49.5852C199.934 49.5852 199.374 50.0762 199.374 50.2671C199.374 50.3352 199.402 50.3898 199.456 50.4307C199.893 50.7852 200.398 51.3034 200.398 52.0807C200.398 53.1307 199.688 54.0307 198.61 54.0307C197.355 54.0307 196.728 52.8716 196.728 51.4534V50.8262C198.406 50.3762 200.329 48.9034 200.329 47.1716C200.329 46.108 199.565 45.358 198.501 45.358C196.359 45.358 195.609 47.7443 195.609 50.0898V51.5489C195.609 53.5262 196.632 55.0534 198.542 55.0534C200.329 55.0534 201.475 53.8125 201.475 52.0943V52.0671C202.035 52.4625 202.662 52.708 203.358 52.708C203.44 52.708 203.467 52.6398 203.467 52.5034C203.467 52.3807 203.44 51.8216 203.372 51.8216ZM198.46 46.3398C198.951 46.3398 199.265 46.6534 199.265 47.158C199.265 48.3307 197.86 49.4489 196.728 49.7898C196.782 47.908 197.328 46.3398 198.46 46.3398Z"
      fill="#28ADA7"
    />
    <path
      d="M206.224 55.0534C208.257 55.0534 209.471 53.8125 209.894 52.558C209.907 52.5171 209.921 52.4625 209.921 52.4216C209.921 52.2989 209.389 52.0398 209.28 52.0398C209.225 52.0398 209.198 52.0671 209.171 52.1352C208.775 53.0898 207.902 54.0852 206.388 54.0852C205.351 54.0852 204.478 53.6216 204.177 52.8034C204.3 52.8171 204.478 52.8171 204.628 52.8171C206.101 52.8171 207.547 52.0125 207.547 50.4307C207.547 49.4625 206.77 48.7534 205.719 48.7534C204.123 48.7534 202.895 50.1989 202.895 51.8898C202.895 53.8534 204.287 55.0534 206.224 55.0534ZM204.45 51.9716C204.273 51.9716 204.137 51.958 204 51.9443C203.986 51.8898 203.986 51.7943 203.986 51.7398C203.973 50.6216 204.778 49.6534 205.705 49.6534C206.197 49.6534 206.538 49.9398 206.538 50.4171C206.538 51.4262 205.528 51.9716 204.45 51.9716Z"
      fill="#28ADA7"
    />
    <path
      d="M213.772 52.0398C213.704 52.0398 213.677 52.0671 213.65 52.1352C213.377 52.8171 212.708 54.0307 211.63 54.0307C210.607 54.0307 210.334 53.2262 210.334 52.0398V49.8716C210.812 49.8852 211.398 49.8989 211.767 49.8989C211.89 49.8989 211.958 49.858 211.958 49.6807V49.1216C211.958 48.9989 211.89 48.9307 211.78 48.9307C211.426 48.9307 210.812 48.9443 210.348 48.958V47.2398C210.348 47.0762 210.266 46.9807 210.116 46.9807H209.42C209.27 46.9807 209.175 47.0762 209.175 47.2534V48.9443C208.984 48.9307 208.82 48.9307 208.684 48.9307C208.533 48.9307 208.479 49.0125 208.479 49.1489V49.6807C208.479 49.8443 208.561 49.8989 208.738 49.8989C208.847 49.8989 208.997 49.8989 209.175 49.8852V52.5852C209.175 54.1125 210.089 55.0534 211.412 55.0534C213.158 55.0534 214.045 53.5398 214.373 52.5716C214.386 52.5307 214.414 52.4625 214.414 52.408C214.414 52.2989 213.868 52.0398 213.772 52.0398Z"
      fill="#28ADA7"
    />
    <path
      d="M216.052 55.0534C217.348 55.0534 218.14 54.1671 218.44 53.3352L218.494 53.3489C218.494 53.4034 218.481 53.5262 218.481 53.6489C218.481 54.4943 219.136 55.0534 220.091 55.0534C221.66 55.0534 222.587 53.4716 222.887 52.558C222.901 52.5171 222.915 52.4625 222.915 52.4216C222.915 52.2989 222.369 52.0398 222.274 52.0398C222.219 52.0398 222.192 52.0671 222.164 52.1352C221.837 52.9125 221.182 54.0307 220.323 54.0307C219.736 54.0307 219.449 53.4852 219.449 52.7216C219.449 51.6443 219.6 49.4352 219.6 49.1762C219.6 48.9852 219.504 48.9034 219.34 48.9034H218.672C218.522 48.9034 218.426 48.9989 218.426 49.1625V49.4898L218.385 49.5034C217.949 49.0943 217.239 48.7534 216.38 48.7534C214.661 48.7534 213.46 50.158 213.46 52.0125C213.46 53.8534 214.579 55.0534 216.052 55.0534ZM216.243 54.0307C215.37 54.0307 214.661 53.2534 214.661 52.0534C214.661 50.6762 215.52 49.7762 216.694 49.7762C217.417 49.7762 218.099 50.2125 218.426 50.5943C218.426 51.8625 217.799 54.0307 216.243 54.0307Z"
      fill="#28ADA7"
    />
  </svg>
);

export default Teenify;
