import React from "react";
import { useState } from "react";
import Teenyfy from "../../images/svgs/teenify";
import Check from "../../images/tick-in-box.svg";
import MenuHam from "../../images/Menu.png";
import CloseMenu from "../../images/closemenu.png";
import { Link } from "gatsby";
import GetStarted from "../getstarted/getstarted";

const Header = () => {
  const linkStyle =
    "lg:inline-flex lg:w-auto w-full px-4 py-4 md:py-2 text-[#1F1F39] items-center justify-center hover:text-[#000] font-[700] md:font-[500] font-open-sans text-center";
  const linkStylefirst =
    "lg:inline-flex lg:w-auto w-full px-6 pt-10 pb-4 md:py-2 text-[#1F1F39] items-center justify-center hover:text-[#000] font-[700] md:font-[500] font-open-sans text-center";
  const linkStylecontact =
    "lg:inline-flex lg:w-auto w-full px-4 pt-4 pb-10 md:py-2 text-[#1F1F39] items-center justify-center hover:text-[#000] font-[700] md:font-[500] font-open-sans text-center";
  const linkStylelogin =
    "lg:inline-flex lg:w-auto w-full px-6 py-4 md:py-2  text-[#1F1F39] items-center justify-center hover:text-[#000] font-[700] md:font-[500] font-open-sans text-center hidden md:block";
  const [active, setActive] = useState(false);

  const [color, setColor] = useState(false);
  const handleClick = () => {
    setActive(!active);
    setColor(!color);
  };
  return (
    <header
      className={`sticky top-0 z-[99] ${color ? "bg-white" : "bg-[#EAFAFA]"}`}
    >
      <div className="container m-auto">
        <nav className="flex items-center flex-wrap p-3 pt-[10px]">
          <a href="/">
            <Teenyfy />
          </a>
          <button
            className="inline-flex p-3 lg:hidden text-[#1F1F39] font-[500] font-open-sans ml-auto hover:text-[#000] outline-none"
            onClick={handleClick}
          >
            {active ? (
              <img src={CloseMenu} alt="" className="w-8 h-8" />
            ) : (
              <img src={MenuHam} alt="" className="w-8 h-8" />
            )}
          </button>

          <div
            className={`${
              active ? "" : "hidden"
            }   w-full lg:inline-flex lg:flex-grow lg:w-auto py-20px`}
          >
            <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto text-center">
              <Link to="/" className={linkStylefirst}>
                <span className="link link-underline link-underline-black">
                  Home
                </span>
              </Link>
              <Link to="/about-us/" className={linkStyle}>
                <span className="link link-underline link-underline-black">
                  Why Teenyfy
                </span>
              </Link>
              <Link to="/bio-link/" className={linkStyle}>
                <span className="link link-underline link-underline-black">
                  Bio Link
                </span>
              </Link>
              <Link to="/pricing/" className={linkStyle}>
                <span className="link link-underline link-underline-black">
                  Pricing
                </span>
              </Link>
              <Link to="/features/" className={linkStyle}>
                <span className="link link-underline link-underline-black">
                  Features
                </span>
              </Link>
              <Link to="/blog/" className={linkStyle}>
                <span className="link link-underline link-underline-black">
                  Blog
                </span>
              </Link>
              <Link to="/contact-us/" className={linkStylecontact}>
                <span className="link link-underline link-underline-black">
                  Contact
                </span>
              </Link>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://app.teenyfy.com/user/login"
                className={linkStylelogin}
              >
                <span className="link link-underline link-underline-black">
                  Login
                </span>
              </a>
              <div className="sm:text-center m-auto md:hidden">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://app.teenyfy.com/user/login"
                  className="flex justify-center items-center bg-transparent w-[160px] h-[54px] px-[30px] md:ml-[20px] text-[#1F1F39] text-center mt-0 mb-[20px] rounded-[40px] font-[16px] font-[700] no-underline leading-[21px] border border-[#1F1F39]"
                >
                  Login
                </a>
              </div>
              <div className="sm:text-center m-auto">
                <GetStarted
                  targetlink="_blank"
                  backgroundCol="bg-dark-green text-white md:ml-[20px]"
                  btntext="Sign up"
                  btnlink="https://app.teenyfy.com/user/register"
                />
              </div>
              <div className="flex mx-auto space-x-2 mt-4 lg:hidden">
                <img src={Check} alt="check" />
                <p>No Credit Card required</p>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
